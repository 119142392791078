import student2023_1 from '../static/students/2023/student2023_1.jpeg'
import student2023_2 from '../static/students/2023/student2023_2.jpeg'
import student2023_3 from '../static/students/2023/student2023_3.jpeg'
import student2023_4 from '../static/students/2023/student2023_4.jpeg'
import student2023_5 from '../static/students/2023/student2023_5.jpeg'
import student2023_6 from '../static/students/2023/student2023_6.jpeg'
import student2023_7 from '../static/students/2023/student2023_7.jpeg'
import student2023_8 from '../static/students/2023/student2023_8.jpeg'
import student2023_9 from '../static/students/2023/student2023_9.jpeg'
import student2023_10 from '../static/students/2023/student2023_10.jpeg'
import student2023_11 from '../static/students/2023/student2023_11.jpeg'
import student2023_12 from '../static/students/2023/student2023_12.jpeg'
import student2023_13 from '../static/students/2023/student2023_13.jpeg'
import student2023_14 from '../static/students/2023/student2023_14.jpeg'
import student2023_15 from '../static/students/2023/student2023_15.jpeg'
import student2023_16 from '../static/students/2023/student2023_16.jpeg'
import student2023_17 from '../static/students/2023/student2023_17.jpeg'
import student2023_18 from '../static/students/2023/student2023_18.jpeg'
import student2023_19 from '../static/students/2023/student2023_19.jpeg'

import student2022_1 from '../static/students/2022/student2022_1.jpeg'
import student2022_2 from '../static/students/2022/student2022_2.jpeg'
import student2022_3 from '../static/students/2022/student2022_3.jpeg'
import student2022_4 from '../static/students/2022/student2022_4.jpeg'
import student2022_5 from '../static/students/2022/student2022_5.jpeg'
import student2022_6 from '../static/students/2022/student2022_6.jpeg'
import student2022_7 from '../static/students/2022/student2022_7.jpeg'
import student2022_8 from '../static/students/2022/student2022_8.jpeg'
import student2022_9 from '../static/students/2022/student2022_9.jpeg'
import student2022_10 from '../static/students/2022/student2022_10.jpeg'
import student2022_11 from '../static/students/2022/student2022_11.jpeg'
import student2022_12 from '../static/students/2022/student2022_12.jpeg'
import student2022_13 from '../static/students/2022/student2022_13.jpeg'
import student2022_14 from '../static/students/2022/student2022_14.jpeg'
import student2022_15 from '../static/students/2022/student2022_15.jpeg'
import student2022_16 from '../static/students/2022/student2022_16.jpeg'
import student2022_17 from '../static/students/2022/student2022_17.jpeg'
import student2022_18 from '../static/students/2022/student2022_18.jpeg'
import student2022_19 from '../static/students/2022/student2022_19.jpeg'
import student2022_20 from '../static/students/2022/student2022_20.jpeg'
import student2022_21 from '../static/students/2022/student2022_21.jpeg'
import student2022_22 from '../static/students/2022/student2022_22.jpeg'
import student2022_23 from '../static/students/2022/student2022_23.jpeg'
import student2022_24 from '../static/students/2022/student2022_24.jpeg'
import student2022_25 from '../static/students/2022/student2022_25.jpeg'
import student2022_26 from '../static/students/2022/student2022_26.jpeg'
import student2022_27 from '../static/students/2022/student2022_27.jpeg'
import student2022_28 from '../static/students/2022/student2022_28.jpeg'
import student2022_29 from '../static/students/2022/student2022_29.jpeg'
import student2022_30 from '../static/students/2022/student2022_30.jpeg'
import student2022_31 from '../static/students/2022/student2022_31.jpeg'
import student2022_32 from '../static/students/2022/student2022_32.jpeg'
import student2022_33 from '../static/students/2022/student2022_33.jpeg'


import student2021_1 from '../static/students/2021/student2021_1.jpeg'
import student2021_2 from '../static/students/2021/student2021_2.jpeg'
import student2021_3 from '../static/students/2021/student2021_3.jpeg'
import student2021_4 from '../static/students/2021/student2021_4.jpeg'
import student2021_5 from '../static/students/2021/student2021_5.jpeg'
import student2021_6 from '../static/students/2021/student2021_6.jpeg'
import student2021_7 from '../static/students/2021/student2021_7.jpeg'
import student2021_8 from '../static/students/2021/student2021_8.jpeg'
import student2021_9 from '../static/students/2021/student2021_9.jpeg'
import student2021_10 from '../static/students/2021/student2021_10.jpeg'
import student2021_11 from '../static/students/2021/student2021_11.jpeg'
import student2021_12 from '../static/students/2021/student2021_12.jpeg'
import student2021_13 from '../static/students/2021/student2021_13.jpeg'
import student2021_14 from '../static/students/2021/student2021_14.jpeg'
import student2021_15 from '../static/students/2021/student2021_15.jpeg'
import student2021_16 from '../static/students/2021/student2021_16.jpeg'
import student2021_17 from '../static/students/2021/student2021_17.jpeg'
import student2021_18 from '../static/students/2021/student2021_18.jpeg'
import student2021_19 from '../static/students/2021/student2021_19.jpeg'
import student2021_20 from '../static/students/2021/student2021_20.jpeg'

import student2020_1 from '../static/students/2020/student2020_1.jpeg'
import student2020_2 from '../static/students/2020/student2020_2.jpeg'
import student2020_3 from '../static/students/2020/student2020_3.jpeg'
import student2020_4 from '../static/students/2020/student2020_4.jpeg'
import student2020_5 from '../static/students/2020/student2020_5.jpeg'
import student2020_6 from '../static/students/2020/student2020_6.jpeg'
import student2020_7 from '../static/students/2020/student2020_7.jpeg'
import student2020_8 from '../static/students/2020/student2020_8.jpeg'
import student2020_9 from '../static/students/2020/student2020_9.jpeg'
import student2020_10 from '../static/students/2020/student2020_10.jpeg'
import student2020_11 from '../static/students/2020/student2020_11.jpeg'
import student2020_12 from '../static/students/2020/student2020_12.jpeg'
import student2020_13 from '../static/students/2020/student2020_13.jpeg'
import student2020_14 from '../static/students/2020/student2020_14.jpeg'
import student2020_15 from '../static/students/2020/student2020_15.jpeg'
import student2020_16 from '../static/students/2020/student2020_16.jpeg'
import student2020_17 from '../static/students/2020/student2020_17.jpeg'
import student2020_18 from '../static/students/2020/student2020_18.jpeg'
import student2020_19 from '../static/students/2020/student2020_19.jpeg'
import student2020_20 from '../static/students/2020/student2020_20.jpeg'

import student2019_1 from '../static/students/2019/student2019_1.jpeg'
import student2019_2 from '../static/students/2019/student2019_2.jpeg'
import student2019_3 from '../static/students/2019/student2019_3.jpeg'
import student2019_4 from '../static/students/2019/student2019_4.jpeg'
import student2019_5 from '../static/students/2019/student2019_5.jpeg'
import student2019_6 from '../static/students/2019/student2019_6.jpeg'
import student2019_7 from '../static/students/2019/student2019_7.jpeg'
import student2019_8 from '../static/students/2019/student2019_8.jpeg'
import student2019_9 from '../static/students/2019/student2019_9.jpeg'
import student2019_10 from '../static/students/2019/student2019_10.jpeg'
import student2019_11 from '../static/students/2019/student2019_11.jpeg'
import student2019_12 from '../static/students/2019/student2019_12.jpeg'
import student2019_13 from '../static/students/2019/student2019_13.jpeg'
import student2019_14 from '../static/students/2019/student2019_14.jpeg'
import student2019_15 from '../static/students/2019/student2019_15.jpeg'
import student2019_16 from '../static/students/2019/student2019_16.jpeg'

import student2018_1 from '../static/students/2018/student2018_1.jpeg'
import student2018_2 from '../static/students/2018/student2018_2.jpeg'
import student2018_3 from '../static/students/2018/student2018_3.jpeg'
import student2018_4 from '../static/students/2018/student2018_4.jpeg'
import student2018_5 from '../static/students/2018/student2018_5.jpeg'
import student2018_6 from '../static/students/2018/student2018_6.jpeg'
import student2018_7 from '../static/students/2018/student2018_7.jpeg'
import student2018_8 from '../static/students/2018/student2018_8.jpeg'
import student2018_9 from '../static/students/2018/student2018_9.jpeg'
import student2018_10 from '../static/students/2018/student2018_10.jpeg'
import student2018_11 from '../static/students/2018/student2018_11.jpeg'
import student2018_12 from '../static/students/2018/student2018_12.jpeg'
import student2018_13 from '../static/students/2018/student2018_13.jpeg'
import student2018_14 from '../static/students/2018/student2018_14.jpeg'

// import student2017_1 from '../static/students/2017/student2017_1.jpeg'
// import student2017_2 from '../static/students/2017/student2017_2.jpeg'
// import student2017_3 from '../static/students/2017/student2017_3.jpeg'
// import student2017_4 from '../static/students/2017/student2017_4.jpeg'
// import student2017_5 from '../static/students/2017/student2017_5.jpeg'
// import student2017_6 from '../static/students/2017/student2017_6.jpeg'
// import student2017_7 from '../static/students/2017/student2017_7.jpeg'
// import student2017_8 from '../static/students/2017/student2017_8.jpeg'
// import student2017_9 from '../static/students/2017/student2017_9.jpeg'
// import student2017_10 from '../static/students/2017/student2017_10.jpeg'
// import student2017_11 from '../static/students/2017/student2017_11.jpeg'
// import student2017_12 from '../static/students/2017/student2017_12.jpeg'
// import student2017_13 from '../static/students/2017/student2017_13.jpeg'
// import student2017_14 from '../static/students/2017/student2017_14.jpeg'
// import student2017_15 from '../static/students/2017/student2017_15.jpeg'
// import student2017_16 from '../static/students/2017/student2017_16.jpeg'

import student2016_1 from '../static/students/2016/students2016_1.jpeg'
import student2016_2 from '../static/students/2016/students2016_2.jpeg'
import student2016_3 from '../static/students/2016/students2016_3.jpeg'
import student2016_4 from '../static/students/2016/students2016_4.jpeg'
import student2016_5 from '../static/students/2016/students2016_5.jpeg'
import student2016_6 from '../static/students/2016/students2016_6.jpeg'
import student2016_7 from '../static/students/2016/students2016_7.jpeg'
import student2016_8 from '../static/students/2016/students2016_8.jpeg'
import student2016_9 from '../static/students/2016/students2016_9.jpeg'
import student2016_10 from '../static/students/2016/students2016_10.jpeg'
import student2016_11 from '../static/students/2016/students2016_11.jpeg'
import student2016_12 from '../static/students/2016/students2016_12.jpeg'

import student2015_1 from '../static/students/2015/students2015_1.jpeg'
import student2015_2 from '../static/students/2015/students2015_2.jpeg'
import student2015_3 from '../static/students/2015/students2015_3.jpeg'
import student2015_4 from '../static/students/2015/students2015_4.jpeg'
import student2015_5 from '../static/students/2015/students2015_5.jpeg'
import student2015_6 from '../static/students/2015/students2015_6.jpeg'
import student2015_7 from '../static/students/2015/students2015_7.jpeg'
import student2015_8 from '../static/students/2015/students2015_8.jpeg'
import student2015_9 from '../static/students/2015/students2015_9.jpeg'
import student2015_10 from '../static/students/2015/students2015_10.jpeg'
import student2015_11 from '../static/students/2015/students2015_11.jpeg'
import student2015_12 from '../static/students/2015/students2015_12.jpeg'
import student2015_13 from '../static/students/2015/students2015_13.jpeg'
//
// import student2014_1 from '../static/students/2014/student2014_1.jpeg'
// import student2014_2 from '../static/students/2014/student2014_2.jpeg'
// import student2014_3 from '../static/students/2014/student2014_3.jpeg'
// import student2014_4 from '../static/students/2014/student2014_4.jpeg'
// import student2014_5 from '../static/students/2014/student2014_5.jpeg'
// import student2014_6 from '../static/students/2014/student2014_6.jpeg'
// import student2014_7 from '../static/students/2014/student2014_7.jpeg'
// import student2014_8 from '../static/students/2014/student2014_8.jpeg'
// import student2014_9 from '../static/students/2014/student2014_9.jpeg'
// import student2014_10 from '../static/students/2014/student2014_10.jpeg'
// import student2014_11 from '../static/students/2014/student2014_11.jpeg'
// import student2014_12 from '../static/students/2014/student2014_12.jpeg'
// import student2014_13 from '../static/students/2014/student2014_13.jpeg'
//
// import student2013_1 from '../static/students/2013/student2013_1.jpeg'
// import student2013_2 from '../static/students/2013/student2013_2.jpeg'
// import student2013_3 from '../static/students/2013/student2013_3.jpeg'
// import student2013_4 from '../static/students/2013/student2013_4.jpeg'
// import student2013_5 from '../static/students/2013/student2013_5.jpeg'
// import student2013_6 from '../static/students/2013/student2013_6.jpeg'
// import student2013_7 from '../static/students/2013/student2013_7.jpeg'
// import student2013_8 from '../static/students/2013/student2013_8.jpeg'
// import student2013_9 from '../static/students/2013/student2013_9.jpeg'
// import student2013_10 from '../static/students/2013/student2013_10.jpeg'
// import student2013_11 from '../static/students/2013/student2013_11.jpeg'
// import student2013_12 from '../static/students/2013/student2013_12.jpeg'
// import student2013_13 from '../static/students/2013/student2013_13.jpeg'
// import student2013_14 from '../static/students/2013/student2013_14.jpeg'
// import student2013_15 from '../static/students/2013/student2013_15.jpeg'
// import student2013_16 from '../static/students/2013/student2013_16.jpeg'
// import student2013_17 from '../static/students/2013/student2013_17.jpeg'
// import student2013_18 from '../static/students/2013/student2013_18.jpeg'
// import student2013_19 from '../static/students/2013/student2013_19.jpeg'
// import student2013_20 from '../static/students/2013/student2013_20.jpeg'
// import student2013_21 from '../static/students/2013/student2013_21.jpeg'



let students2023 = [
    {
        name: 'Латыпова Алина',
        study: 'Казанский федеральный университет',
        special: 'лингвистика: перевод и переводоведение',
        photo: student2023_1,
    },
    {
        name: 'Филиппов Альберт',
        study: 'Казанский государственный аграрный университет ',
        special: 'наземные транспортно-технологические средства',
        photo: student2023_2,
    },
    {
        name: 'Пфеффер Виктория',
        study: 'Казанский федеральный университет',
        special: 'педагогическое образование с двумя профилями подготовки: иностранные языки',
        photo: student2023_3,
    },
    {
        name: 'Алехина Ксения',
        study: 'Государственный университет управления, г. Москва ',
        special: 'маркетинг',
        photo: student2023_4,
    },
    {
        name: 'Садыков Булат',
        study: 'Казанский государственный медицинский университет',
        special: 'стоматология',
        photo: student2023_5,
    },
    {
        name: 'Гуриелидзе Лаура и Медея',
        study: 'Российский национальный исследовательский медицинский университет имени Н. И. Пирогова, г. Москва',
        special: 'лечебное дело',
        photo: student2023_6,
    },
    {
        name: 'Хасанова Дина',
        study: 'Казанский государственный медицинский университет',
        special: 'педиатрия',
        photo: student2023_7,
    },
    {
        name: 'Панченко Анастасия',
        study: 'Казанский федеральный университет',
        special: 'реклама и связи с общественностью',
        photo: student2023_8,
    },
    {
        name: 'Талина Станислава',
        study: 'Казанский федеральный университет',
        special: 'коммуникативный дизайн',
        photo: student2023_9,
    },
    {
        name: 'Хамитов Линар',
        study: 'Казанский федеральный университет',
        special: 'история и английский язык в полилингвальной среде',
        photo: student2023_10,
    },
    {
        name: 'Сахарова Дарья',
        study: 'Казанский государственный энергетический университет',
        special: 'теплоэнергетика и теплофизика',
        photo: student2023_11,
    },
    {
        name: 'Дегтярева Мария',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'реконструкция и реставрация архитектурного наследия',
        photo: student2023_12,
    },
    {
        name: 'Халилова Дарья',
        study: 'Казанский федеральный университет',
        special: 'реклама и связи с общественностью',
        photo: student2023_13,
    },
    {
        name: 'Исмагилова Дина',
        study: 'Griffith University, Dublin',
        special: 'graphic communication design',
        photo: student2023_14,
    },
    {
        name: 'Хамидуллина Диана',
        study: 'Казанский государственный медицинский университет',
        special: 'стоматология',
        photo: student2023_15,
    },
    {
        name: 'Тощева Анна',
        study: 'Казанский федеральный университет',
        special: 'лечебное дело',
        photo: student2023_16,
    },
    {
        name: 'Егоров Роман',
        study: 'Казанский федеральный университет',
        special: 'экономика',
        photo: student2023_17,
    },
    {
        name: 'Асадуллина Элина',
        study: 'Казанский федеральный университет',
        special: 'клиническая психология',
        photo: student2023_18,
    },
    {
        name: 'Рахманкулова Аделя',
        study: 'Казанский федеральный университет',
        special: 'специальная психология',
        photo: student2023_19,
    },
]

let students2022 = [
    {
        name: 'Хуснутдинова Лия',
        study: 'Казанский государственный медицинский университет',
        special: 'лечебное дело',
        photo: student2022_1,
    },
    {
        name: 'Абрамова Анастасия',
        study: 'Российский национальный исследовательский медицинский университет имени \n' +
            'Н. И. Пирогова, г. Москва',
        special: 'лечебное дело',
        photo: student2022_2,
    },
    {
        name: 'Кофтун Елизавета',
        study: 'Финансовый университет при Правительстве РФ, г. Москва',
        special: 'реклама и связи с общественностью',
        photo: student2022_3,
    },
    {
        name: 'Гарипова Алина',
        study: 'Казанский федеральный университет',
        special: 'реклама и связи с общественностью',
        photo: student2022_4,
    },
    {
        name: 'Маврина Влада',
        study: 'Казанский федеральный университет',
        special: 'международные отношения',
        photo: student2022_5,
    },
    {
        name: 'Абузярова Ясмина',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектурное проектирование',
        photo: student2022_6,
    },
    {
        name: 'Куляпин Владимир',
        study: 'Казанский национальный исследовательский технический университет имени А. Н. Туполева',
        special: 'техническая физика',
        photo: student2022_7,
    },
    {
        name: 'Жукова Полина',
        study: 'Казанский федеральный университет',
        special: 'экономика АТЭС',
        photo: student2022_8,
    },
    {
        name: 'Яруллин Султан',
        study: 'Heroit watt university, Dubai',
        special: 'business administration',
        photo: student2022_9,
    },
    {
        name: 'Евграфова Кристина',
        study: 'Казанский федеральный университет',
        special: 'реклама и связи с общественностью',
        photo: student2022_10,
    },
    {
        name: 'Мангушева Аделина',
        study: 'Приволжский исследовательский медицинский университет, г. Нижний Новгород ',
        special: 'лечебное дело',
        photo: student2022_11,
    },
    {
        name: 'Шаймарданова Эльвина',
        study: 'Казанский федеральный университет',
        special: 'клиническая психология',
        photo: student2022_12,
    },
    {
        name: 'Волкова Екатерина',
        study: 'Казанский федеральный университет',
        special: 'прикладная информатика',
        photo: student2022_13,
    },
    {
        name: 'Иванов Алексей',
        study: 'Казанский федеральный университет',
        special: 'информационная безопасность телекоммуникационных систем',
        photo: student2022_14,
    },
    {
        name: 'Шарифуддин Марсель',
        study: 'Казанский национальный исследовательский технологический университет',
        special: 'бизнес-статистика и экономика',
        photo: student2022_15,
    },
    {
        name: 'Гайнутдинов Марат',
        study: 'Казанский инновационный университет имени В. Г. Тимирясова',
        special: 'прикладная информатика',
        photo: student2022_16,
    },
    {
        name: 'Злобина Анастасия',
        study: 'Казанский государственный энергетический университет',
        special: 'реклама и связи с общественностью',
        photo: student2022_17,
    },
    {
        name: 'Фатенок София',
        study: 'Казанский национальный исследовательский технологический университет',
        special: 'промышленный дизайн и материалы художественных изделий',
        photo: student2022_18,
    },
    {
        name: 'Ахметова Динара',
        study: 'Московский архитектурный университет',
        special: 'архитектура',
        photo: student2022_19,
    },
    {
        name: 'Ахметова Диляра',
        study: 'Московский архитектурный университет',
        special: 'архитектура',
        photo: student2022_20,
    },
    {
        name: 'Баринова Ева',
        study: 'Instituto Europeo di Design (Европейский институт дизайна, Италия, Милан)',
        special: 'графический дизайн',
        photo: student2022_21,
    },
    {
        name: 'Мансурова Эвелина',
        study: 'Казанский федеральный университет',
        special: 'лечебное дело',
        photo: student2022_22,
    },
    {
        name: 'Абрамов Кирилл',
        study: 'Казанский федеральный университет',
        special: 'современная разработка программного обеспечения',
        photo: student2022_23,
    },
    {
        name: 'Грибова Мария',
        study: 'Российский государственный гуманитарный университет, г. Москва',
        special: 'реклама и связи с общественностью',
        photo: student2022_24,
    },
    {
        name: 'Константинова Валерия',
        study: 'Казанский федеральный университет',
        special: 'менеджмент',
        photo: student2022_25,
    },
    {
        name: 'Махмутова Алина',
        study: 'Московский государственный университет ',
        special: 'международная экономика',
        photo: student2022_26,
    },
    {
        name: 'Ячанова Василиса',
        study: 'Британская Высшая Школа Дизайна, г. Москва',
        special: 'foundation art and design',
        photo: student2022_27,
    },
    {
        name: 'Васильева Александра',
        study: 'Казанский федеральный университет',
        special: 'педагогическое образование с двумя профилями подготовки: английский и испанский языки',
        photo: student2022_28,
    },
    {
        name: 'Галиуллина Камилла',
        study: 'Высшая школа экономики, г. Москва',
        special: 'экономика и политика в Азии',
        photo: student2022_29,
    },
    {
        name: 'Черепанова Софья',
        study: 'Казанский федеральный университет',
        special: 'юриспруденция',
        photo: student2022_30,
    },
    {
        name: 'Буренина Дарья',
        study: 'University of Alicante, Испания',
        special: 'business management',
        photo: student2022_31,
    },
    {
        name: 'Гуриелидзе Лия',
        study: 'Московский государственный университет ',
        special: 'фундаментальная медицина',
        photo: student2022_32,
    },
    {
        name: 'Алиева Тальмира',
        study: 'Казанский федеральный университет',
        special: 'реклама и связи с общественностью',
        photo: student2022_33,
    },
]

let students2021 = [
    {
        id: 1,
        name: 'Елкина Дарина',
        study: 'Первый Московский государственный медицинский университет имени И. М. Сеченова',
        special: 'педиатрия',
        photo: student2021_1,
    },

    {
        id: 2,
        name: 'Хабибуллин Артём',
        study: 'Российский университет дружбы народов',
        special: 'лингвистика',
        photo: student2021_2,
    },

    {
        id: 3,
        name: 'Хабибуллина Аделина',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектурное проектирование',
        photo: student2021_3,
    },

    {
        id: 4,
        name: 'Гимадутдинова Алсу',
        study: 'Казанская государственная академия ветеринарной медицины имени Н. Э. Баумана',
        special: 'ветеринария',
        photo: student2021_4,
    },

    {
        id: 5,
        name: 'Гимранов Булат',
        study: 'Казанский государственный энергетический университет',
        special: 'электрические станции и подстанции',
        photo: student2021_5,
    },

    {
        id: 6,
        name: 'Шуркина Мария',
        study: 'Казанский инновационный университет имени В. Г.Тимирясова',
        special: 'начальное образование',
        photo: student2021_6,
    },

    {
        id: 7,
        name: 'Сибгатуллин Эмиль',
        study: 'Российский государственный университет нефти и газа имени И. М. Губкина',
        special: 'химическая технология',
        photo: student2021_7,
    },

    {
        id: 8,
        name: 'Гайнуллина Камилла',
        study: 'Казанский федеральный университет ',
        special: 'лечебное дело)',
        photo: student2021_8,
    },

    {
        id: 9,
        name: 'Бигеева Иделина',
        study: 'Казанский федеральный университет ',
        special: 'экономика',
        photo: student2021_9,
    },

    {
        id: 10,
        name: 'Зигангараева Алина',
        study: 'Московский политехнический университет',
        special: 'информационные системы и технологии',
        photo: student2021_10,
    },

    {
        id: 11,
        name: 'Фаттахов Тимерхан',
        study: 'Казанский инновационный университет ',
        special: 'прикладная информатика в области экономики',
        photo: student2021_11,
    },

    {
        id: 12,
        name: 'Гайнуллин Эрик',
        study: 'Российский университет транспорта',
        special: 'Московский институт инженеров транспорта',
        photo: student2021_12,
    },

    {
        id: 13,
        name: 'Воробьев Иван',
        study: 'Московский энергетический институт ',
        special: 'радионавигационные системы и комплексы',
        photo: student2021_13,
    },

    {
        id: 14,
        name: 'Новиков Егор',
        study: 'Казанский государственный энергетический университет',
        special: 'технология разработки программного обеспечения',
        photo: student2021_14,
    },

    {
        id: 15,
        name: 'Захаров Клим',
        study: 'Казанский национальный исследовательский технический университет имени А. Н. Туполева' ,
        special: 'техническая эксплуатация летательных аппаратов и двигателей',
        photo: student2021_15,
    },

    {
        id: 16,
        name: 'Бахитова Эмилия',
        study: 'Мордовский государственный университет имени Н. П. Огарева',
        special: 'лечебное дело',
        photo: student2021_16,
    },

    {
        id: 17,
        name: 'Феоктистова Анастасия',
        study: 'Казанский федеральный университет ',
        special: 'лингвистика; теория и методика преподавания иностранных языков и культур',
        photo: student2021_17,
    },

    {
        id: 18,
        name: 'Хуснетдинова Мелиса',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'промышленное и гражданское строительство',
        photo: student2021_18,
    },

    {
        id: 19,
        name: 'Емельянова София',
        study: 'Казанский федеральный университет ',
        special: 'медиакоммуникации в социальных системах',
        photo: student2021_19,
    },

    {
        id: 20,
        name: 'Фомин Ярослав',
        study: 'Дипломатическая академия Министерства иностранных дел Российской Федерации ',
        special: 'международные отношения',
        photo: student2021_20,
    },


]

let students2020 = [
    {
        name: 'Шинкарёв Игорь',
        study: 'Национальный исследовательский технологический университет «МИСиС»',
        special: 'прикладная информатика',
        photo: student2020_1,
    },
    {
        name: 'Фахрутдинов Аяз',
        study: 'Московский государственный технический университет им.Н.Э.Баумана',
        special: 'конструирование и технология электронных средств',
        photo: student2020_2,
    },
    {
        name: 'Хасанов Роберт',
        study: 'Казанский федеральный университет',
        special: 'телевизионная журналистика',
        photo: student2020_3,
    },
    {
        name: 'Черногоров Аркадий',
        study: 'Национальный исследовательский технологический университет «МИСиС»',
        special: 'информатика и вычислительная техника',
        photo: student2020_4,
    },
    {
        name: 'Тимербулатов Амирхан',
        study: 'University of Bristol',
        special: 'Social Sciences and Law Programme; British Law',
        photo: student2020_5,
    },
    {
        name: 'Шайхутдинов Адель',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'радиоэлектронные системы и комплексы',
        photo: student2020_6,
    },
    {
        name: 'Рахимова Ильгина',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'экспертиза и управление недвижимостью',
        photo: student2020_7,
    },
    {
        name: 'Смирнов Никита, Сидоренков Артем, Алексеев Данил',
        study: 'Казанский федеральный университет',
        special: 'международный бизнес',
        photo: student2020_8,
    },
    {
        name: 'Шакиров Ансар',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'строительство уникальных зданий и сооружений',
        photo: student2020_9,
    },
    {
        name: 'Погодина Арина',
        study: 'Казанский федеральный университет',
        special: 'лингвистика, теория и методика преподавания иностранных языков',
        photo: student2020_10,
    },
    {
        name: 'Травин Олег',
        study: 'Поволжская государственная академия физической культуры, спорта и туризма',
        special: 'спортивный менеджмент',
        photo: student2020_11,
    },
    {
        name: 'Садреев Ильхан',
        study: 'Военный университет Министерства обороны Российской Федерации',
        special: '',
        photo: student2020_12,
    },
    {
        name: 'Кудрина Елизавета',
        study: 'Казанский государственный аграрный университет',
        special: 'техническая переработка сельхозпродукции',
        photo: student2020_13,
    },
    {
        name: 'Мишарин Александр',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'менеджмент',
        photo: student2020_14,
    },
    {
        name: 'Курбанов Давид',
        study: 'Казанский федеральный университет',
        special: 'стоматология',
        photo: student2020_15,
    },
    {
        name: 'Генина Анастасия',
        study: 'Казанский федеральный университет',
        special: 'лингвистика',
        photo: student2020_16,
    },
    {
        name: 'Гелеверя Глеб',
        study: 'Казанский федеральный университет',
        special: 'геология',
        photo: student2020_17,
    },
    {
        name: 'Алексеев Артём',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'Информационные системы и технологии',
        photo: student2020_18,
    },
    {
        name: 'Венедиктов Рафаил',
        study: 'Университет Иннополис',
        special: 'информатика и вычислительная техника',
        photo: student2020_19,
    },
    {
        name: 'Белов Максим',
        study: 'Казанский федеральный университет',
        special: 'лингвистика',
        photo: student2020_20,
    },
]

let students2019 = [
    {
        name: 'Исмаилова Алавия',
        study: 'Rotterdam University of Applied Sciences',
        special: 'международный бизнес и менеджмент',
        photo: student2019_1,
    },
    {
        name: 'Мухаметзянова Ралина',
        study: 'Высшая школа экономики',
        special: 'прикладная математика и информатика',
        photo: student2019_2,
    },
    {
        name: 'Сулейманова Эмилия',
        study: 'Казанский федеральный университет',
        special: 'юриспруденция',
        photo: student2019_3,
    },
    {
        name: 'Юматова Галия',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектура',
        photo: student2019_4,
    },
    {
        name: 'Цымбал Михаил',
        study: 'Московский энергетический институт',
        special: 'радиоэлектронные системы и комплексы',
        photo: student2019_5,
    },
    {
        name: 'Трофимова Софья',
        study: 'Санкт-Петербургский политехнический университет Петра Великого ',
        special: 'компьютерная безопасность',
        photo: student2019_6,
    },
    {
        name: 'Семенова Дарья',
        study: 'Казанский федеральный университет',
        special: 'экономика',
        photo: student2019_7,
    },
    {
        name: 'Камалов Нияз',
        study: 'Казанский федеральный университет',
        special: 'юриспруденция',
        photo: student2019_8,
    },
    {
        name: 'Шилова Анастасия',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектура',
        photo: student2019_9,
    },
    {
        name: 'Сибгатуллин Альберт',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'радиотехника',
        photo: student2019_10,
    },
    {
        name: 'Воробьева Ирина',
        study: 'Кировский государственный медицинский университет',
        special: 'лечебное дело',
        photo: student2019_11,
    },
    {
        name: 'Салимов Рустем',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'управление в технических системах',
        photo: student2019_12,
    },
    {
        name: 'Хакимзянова Алина',
        study: 'Российский национальный исследовательский медицинский университет имени Н.И.Пирогова',
        special: 'стоматология',
        photo: student2019_13,
    },
    {
        name: 'Шарапова Саида',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектура',
        photo: student2019_14,
    },
    {
        name: 'Фролов Михаил',
        study: 'Высшая школа экономики',
        special: 'социология',
        photo: student2019_15,
    },
    {
        name: 'Шафикова Дина',
        study: 'Уфимский государственный авиационный технический университет',
        special: 'математическое обеспечение и администрирование информационных систем',
        photo: student2019_16,
    },
]

let students2018 = [
    {
        name: 'Исхаков Артём',
        study: 'Санкт-Петербургский горный университет',
        special: 'радиоэлектронные системы и комплексы',
        photo: student2018_1,
    },
    {
        name: 'Хафизов Равиль',
        study: 'Казанский  федеральный университет',
        special: 'гражданское право',
        photo: student2018_2,
    },
    {
        name: 'Валиев Рамазан',
        study: 'Санкт-Петербургский государственный политехнический университет',
        special: 'программная инженерия',
        photo: student2018_3,
    },
    {
        name: 'Хабибуллина Элеонора',
        study: 'Казанский федеральный университет',
        special: 'бизнес-информатика',
        photo: student2018_4,
    },
    {
        name: 'Багаутдинова Элиза',
        study: 'Казанский национальный исследовательский технологический университет',
        special: 'информатика и вычислительная техника',
        photo: student2018_5,
    },
    {
        name: 'Низами Ислам',
        study: 'Казанский государственный медицинский университет',
        special: 'педиатрический факультет',
        photo: student2018_6,
    },
    {
        name: 'Макарова Софья',
        study: 'Поволжская государственная академия физической культуры, спорта и туризма',
        special: 'спортивная тренировка в избранном виде спорта',
        photo: student2018_7,
    },
    {
        name: 'Кузьмичёв Константин',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'радиотехника',
        photo: student2018_8,
    },
    {
        name: 'Самигуллина Карима',
        study: 'Марийский государственный университет',
        special: 'лечебное дело',
        photo: student2018_9,
    },
    {
        name: 'Аюпова Залина',
        study: 'Санкт-Петербургский государственный университет',
        special: 'государственное и муниципальное управление',
        photo: student2018_10,
    },
    {
        name: 'Семыкин Артур',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'прикладная информатика',
        photo: student2018_11,
    },
    {
        name: 'Феоктистов Дмитрий',
        study: 'Казанский государственный энергетический университет',
        special: 'электроэнергетические системы и сети',
        photo: student2018_12,
    },
    {
        name: 'Новикова Ангелина',
        study: 'Высшая школа экономики',
        special: 'международные отношения',
        photo: student2018_13,
    },
    {
        name: 'Рахимова Эльвина',
        study: 'Казанский государственный архитектурно-строительный университет ',
        special: 'экспертиза и управление недвижимостью',
        photo: student2018_14,
    },
]

// let students2017 = [
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_1,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_2,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_3,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_4,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_5,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_6,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_7,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_8,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_9,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_10,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_11,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_12,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_13,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_14,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_15,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2017_16,
//     },
// ]
//
let students2016 = [
    {
        name: 'Бердников Андрей',
        study: 'Казанский федеральный университет',
        special: 'информационные системы и технологии',
        photo: student2016_1,
    },
    {
        name: 'Котов Виталий',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'самолёто- и вертолетостроение',
        photo: student2016_2,
    },
    {
        name: 'Лаптев Даниил',
        study: 'Казанский национальный исследовательский технический университет',
        special: 'самолёто- и вертолетостроение',
        photo: student2016_3,
    },
    {
        name: 'Ройзенблит Олеся',
        study: 'Казанский национальный исследовательский технический университет ',
        special: 'информационные системы и технологии',
        photo: student2016_4,
    },
    {
        name: 'Титов Роман',
        study: 'Казанский медицинский колледж ',
        special: 'стоматология',
        photo: student2016_5,
    },
    {
        name: 'Толкушкина Татьяна',
        study: 'Казанский национальный исследовательский технологический университет',
        special: '',
        photo: student2016_6,
    },
    {
        name: 'Хуснетдинова Камилла',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектурное проектирование',
        photo: student2016_7,
    },
    {
        name: 'Шайхутдинова Римма',
        study: 'Казанский государственный архитектурно-строительный университет',
        special: 'архитектурное проектирование',
        photo: student2016_8,
    },
    {
        name: 'Шакиров Рузаль',
        study: 'Казанский национальный исследовательский технологический университет.',
        special: '',
        photo: student2016_9,
    },
    {
        name: 'Грязнов Никита',
        study: 'Санкт-Петербургский университет телекоммуникаций и систем связи имени Бонч-Бруевича (СПБ ГУТ).',
        special: '',
        photo: student2016_10,
    },
    {
        name: 'Шакиров Раил',
        study: 'Казанский национальный исследовательский технологический университет',
        special: '',
        photo: student2016_11,
    },
    {
        name: 'Троицкая Катерина',
        study: 'Казанский федеральный университет',
        special: 'экономический факультет',
        photo: student2016_12,
    },
]

let students2015 = [
    {
        name: 'Копунов Михаил',
        study: 'Казанский национальный исследовательский технический университет',
        special: '',
        photo: student2015_1,
    },
    {
        name: 'Аласгарли Сеймур',
        study: 'Казанский национальный исследовательский технологический университет',
        special: '',
        photo: student2015_2,
    },
    {
        name: 'Фейруза Газиева',
        study: 'Казанский госудраственный медицинский университет',
        special: '',
        photo: student2015_3,
    },
    {
        name: 'Мухаметдинова Диана',
        study: 'Казанский федеральный университет',
        special: '',
        photo: student2015_4,
    },
    {
        name: 'Аксагова Екатерина',
        study: 'Фрайбургский университет им. Альберта и Людвига (Германия)',
        special: '',
        photo: student2015_5,
    },
    {
        name: 'Васягин Глеб',
        study: 'Казанский национальный исследовательский технологический университет',
        special: '',
        photo: student2015_6,
    },
    {
        name: 'Сиразетдинова Алина',
        study: 'Казанский федеральный университет',
        special: '',
        photo: student2015_7,
    },
    {
        name: 'Васильевских Павел',
        study: 'Казанский национальный исследовательский технологический университет',
        special: '',
        photo: student2015_8,
    },
    {
        name: 'Белов Эдгар',
        study: 'Казанский национальный исследовательский технический университет',
        special: '',
        photo: student2015_9,
    },
    {
        name: 'Биктагирова Камилла',
        study: 'Институт экономики, управления и права',
        special: '',
        photo: student2015_10,
    },
    {
        name: 'Сафонова Мария',
        study: 'D\'ovebrooks college (Оксфорд, Англия)',
        special: '',
        photo: student2015_11,
    },
    {
        name: 'Зильберштейн Дина',
        study: 'Высшая школа экономики (Москва)',
        special: '',
        photo: student2015_12,
    },
    {
        name: 'Ракипов Тимур',
        study: 'Казанский национальный исследовательский технический университет',
        special: '',
        photo: student2015_13,
    },
]

// let students2014 = [
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_1,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_2,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_3,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_4,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_5,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_6,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_7,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_8,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_9,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_10,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_11,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_12,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2014_13,
//     },
// ]
//
// let students2013 = [
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_1,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_2,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_3,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_4,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_5,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_6,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_7,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_8,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_9,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_10,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_11,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_12,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_13,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_14,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_15,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_16,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_17,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_18,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_19,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_20,
//     },
//     {
//         name: '',
//         study: '',
//         special: '',
//         photo: student2013_21,
//     },
// ]


export const studentsMap= new Map();

studentsMap.set('2023', students2023)
studentsMap.set('2022', students2022)
studentsMap.set('2021', students2021)
studentsMap.set('2020', students2020)
studentsMap.set('2019', students2019)
studentsMap.set('2018', students2018)
// studentsMap.set('2017', students2017)
studentsMap.set('2016', students2016)
studentsMap.set('2015', students2015)
// studentsMap.set('2014', students2014)
// studentsMap.set('2012-2013', students2013)




