import students2019_all from "../static/students2023_all.jpg";
import image3 from "../static/location/image3.jpg"
import image1 from "../static/location/image1.jpg"
import girl from "../static/reviews/girl.webp";
import boy from "../static/reviews/boy.webp";
import home1 from "../static/home1.jpg"
import home2 from "../static/home2.jpg"
import home3 from "../static/home3.jpg"


export const homePhoto = [
    {
        link: "/about",
        image: home1,
        title: 'Гильдия основана в  2013 году как профессиональное сообщество преподавателей ведущих казанских университетов',
    },
    {
        link: "/teachers",
        image: home2,
        title: 'Сильная команда преподаватей; собственная учебная литература',
    },
    {
        link: "/exam-preparation",
        image: home3,
        title: 'Занятия проводятся в группах по 3-5 человек (усиленной подготовки), консультации – индивидуально',
    },
    {
        link: "/students",
        image: students2019_all,
        title: 'Множество выпускников с отличными результатами, поступившие в вузы мечты',
    },
    {
        link: "/how-to-find",
        image: image1,
        title: 'Наши классы находятся в историческом центре Казани',
    },
]

export const homeReviews = [
    {
        name: 'Алина Латыпова',
        content: "Мне кажется, никто не может лучше подготовить к ЕГЭ по русскому языку,\n" +
            "чем Татьяна Викторовна \n" +
            "Каждое занятие, можно сказать, приближало к успеху: вся необходимая, годами усовершенствованная и проработанная теория, доскональная проверка сочинений (придирки возможны к каждому слову, но это и позволяет набрать максимум на ЕГЭ ), а также эмоциональная поддержка.\n" +
            "Безусловно, именно Татьяне Викторовне, её труду и знаниям я и обязана заветным 100 баллам!",
        date: '01.09.23',
        photo: girl
    },
    {
        name: 'Абрамова Анастасия',
        content: 'Татьяна Викторовна провела большую исследовательскую работу в разделе подготовки к ЕГЭ, что позволило ей создать свой уникальный метод плавной, но точной подготовки! Мой результат – это наша совместная работа. Я не скажу, что было просто, но благодаря тому, какой Татьяна Викторовна человек и педагог, всё трудности были преодолены и я получила эти заветные 100 баллов!',
        date: '17.08.22',
        photo: girl
    },
    {
        name: 'Роман Егоров',
        content: "Преподаватели Гильдии являются настоящими профессионалами.\n" +
            "Занятия проводятся очень интересно и продуктивно. Преподавателю всегда можно задать вопрос и получить на него исчерпывающий ответ. Благодаря Гильдии и преподавателям я смог добиться своей цели.\n",
        date: '31.08.23',
        photo: boy
    },
    {
        name: 'Евграфова Кристина',
        content: 'Мне очень понравилось готовиться к ЕГЭ вместе с Татьяной Викторовной. В Гильдии создаётся очень домашняя и уютная атмосфера. Если у меня оставались вопросы, то мы обязательно разбирали их, а также я могла прийти дополнительно и спросить все, что мне было не совсем понятно. Очень крутая идея с консультацией по сочинению. Мне это очень-очень помогло: я полностью поняла структуру и легко запомнила шаблон:)',
        date: '10.08.22',
        photo: girl
    },
]